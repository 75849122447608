.notification {
  &.notification-success {
    .toast-header {
      background: rgba(225,248,230,.85) !important;
      color: #19692b !important;
    }
  }
  &.notification-info {
    .toast-header {
      background: rgba(225,248,248,.85) !important;
      color: #055160 !important;
    }
  }
  &.notification-warning {
    .toast-header {
      background: rgba(255,249,231,.85) !important;
      color: #b98b00 !important;
    }
  }
  &.notification-error {
    .toast-header {
      background: rgba(252,240,241,.85) !important;
      color: #a71d2a !important;
    }
  }
}