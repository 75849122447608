.tooltip-wrapper {
  position: relative;
  display: inline-block;

  .tooltip {
    display: block;
    opacity: 1;
    position: absolute;

    .tooltip-inner {
      white-space: nowrap;
    }

    &.tooltip-top {
      top: 0;
      bottom: auto;
      left: 50%;
      right: auto;
      transform: translate(-50%, -100%);
      .tooltip-arrow {
        left: 50%;
        right: auto;
        transform: translate(-50%, 0);
      }
    }
    &.tooltip-left {
      top: 50%;
      bottom: auto;
      left: 0;
      right: auto;
      transform: translate(-100%, -50%);
      .tooltip-arrow {
        top: 50%;
        left: auto;
        right: 0;
        transform: translate(0, -50%);
      }
    }
    &.tooltip-right {
      top: 50%;
      bottom: auto;
      left: auto;
      right: 0;
      transform: translate(100%, -50%);
      .tooltip-arrow {
        top: 50%;
        left: 0;
        right: auto;
        transform: translate(0, -50%);
      }
    }
    &.tooltip-bottom {
      top: auto;
      bottom: 0;
      left: 50%;
      right: auto;
      transform: translate(-50%, 100%);
      .tooltip-arrow {
        left: 50%;
        right: auto;
        transform: translate(-50%, 0);
      }
    }
    &.tooltip-top-left {
      top: 0;
      bottom: auto;
      left: auto;
      right: 50%;
      transform: translate(12px, -100%);
      .tooltip-arrow {
        left: auto;
        right: 12px;
        transform: translate(50%, 0);
      }
    }
    &.tooltip-top-right {
      top: 0;
      bottom: auto;
      left: 50%;
      right: auto;
      transform: translate(-12px, -100%);
      .tooltip-arrow {
        left: 12px;
        right: auto;
        transform: translate(-50%, 0);
      }
    }
    &.tooltip-bottom-left {
      top: auto;
      bottom: 0;
      left: auto;
      right: 50%;
      transform: translate(12px, 100%);
      .tooltip-arrow {
        left: auto;
        right: 12px;
        transform: translate(50%, 0);
      }
    }
    &.tooltip-bottom-right {
      top: auto;
      bottom: 0;
      left: 50%;
      right: auto;
      transform: translate(-12px, 100%);
      .tooltip-arrow {
        left: 12px;
        right: auto;
        transform: translate(-50%, 0);
      }
    }
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}