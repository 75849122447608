@import (once) '@App/vars.less';
@import (once) '@App/media.less';

#menu-row {
  .media-sm-down({
    display: none;
    &.open {
      display: block;
    }
  });
}

#main-menu {
  margin: 0 -12px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li {
      position: relative;
      a {
        color: @secondaryColor;
        padding: 15px 12px;
        display: block;
        text-decoration: none !important;
        span {
          display: inline-block;
          width: 22px;
          text-align: center;
        }
      }
      &.active,
      &:hover {
        a {
          color: @primaryColor;
        }
      }
    }
  }
  .media-sm-down({
    margin: 0;
    li {
      a {
        font-size: 2rem;
        padding: 3px 15px !important;
        span {
          width: 36px !important;
        }
      }
    }
  });
}