.modal {
  background: rgba(0, 0, 0, 0.2);
  display: block;
  opacity: 1;
  animation: fadeIn 0.15s 1 ease-in-out;
  .modal-dialog {
    transform: translateY(0);
    animation: slideDown 0.15s 1 ease-in-out;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}