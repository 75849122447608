@import (once) '@App/vars.less';
@import (once) '@App/media.less';

#user-bar {
  #user-companies,
  #user-profile {
    min-height: 54px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 5px;
    position: relative;
    color: #fff;
    line-height: 1.2;

    .dropdown-menu {
      bottom: 0;
      transform: translate(0, 100%);

      .dropdown-item {
        cursor: pointer !important;
      }
    }

    &:hover {
      background: @primaryColor;
    }
  }
  #user-companies {
    display: flex;
    align-items: center;

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }

    .dropdown-menu {
      left: 0;
      right: auto;
      bottom: 0;
      transform: translate(0, 100%);
    }
  }
  #user-profile {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 5px;
    position: relative;

    .dropdown-menu {
      left: auto;
      right: 0;
      bottom: 0;
      transform: translate(0, 100%);
    }

    & > div {
      display: flex;
      align-items: center;
      & > div {
        padding: 5px;
      }
    }

    .role {
      color: @secondaryColor;
      font-size: 12px;
    }
    .avatar {
      display: block;
      width: 34px;
      height: 34px;
      border-radius: 999px;
      background: @secondaryColor;
    }

    &:hover {
      .role {
        color: @darkBgColor;
      }
    }
  }
}