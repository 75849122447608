.calendar-sheet {
  border: 1px solid #ddd;
  .calendar-header {
    display: flex;
    & > div {
      flex: 1;
      text-align: center;
      border: 1px solid #ddd;
      font-size: 12px;
      padding: 4px 0;
    }
  }
  .calendar-body {
    .calendar-week {
      display: flex;
      & > div {
        flex: 1;
        text-align: center;
        border: 1px solid #ddd;
        font-size: 12px;
        padding: 4px 0;
        cursor: pointer;
        &.calendar-out-date {
          color: #ccc;
        }
        &.calendar-date {
          &.today {
            color: #0dcaf0;
            font-weight: bold;
          }
          &.active {
            color: #fff;
            background: #0dcaf0;
            font-weight: bold;
          }
        }
      }
    }
  }
}