@import (once) '@App/vars.less';
@import (once) '@App/media.less';

#main-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  #header {
    background: @darkBgColor;
    box-shadow: 3px 0 10px 0 @shadowColor;

    .header-row {
      min-height: 54px;
    }
    & > div:not(:last-child) {
      border-bottom: 1px solid fade(@lightBorderColor, 10%);
    }

    #logo {
      color: #fff !important;
      text-decoration: none !important;
      font-size: 28px;
      height: 32px;
      display: flex;
      flex-wrap: nowrap;

      img {
        height: 32px;
        display: block;
      }

      span {
        line-height: 32px;
        display: block;
        margin-left: 10px;

        .media-sm-down({
          display: none;
        });
      }
    }
  }
  #body {
    flex: 1;
  }
  #footer {
    border-top: 1px solid @lightColor;
    font-size: .8rem;
    color: @secondaryColor;
  }
}