.media-sm-up(@content) {
  @media (min-width: 576px) {
    @content();
  }
}
.media-md-up(@content) {
  @media (min-width: 768px) {
    @content();
  }
}
.media-lg-up(@content) {
  @media (min-width: 992px) {
    @content();
  }
}
.media-xl-up(@content) {
  @media (min-width: 1200px) {
    @content();
  }
}
.media-xxl-up(@content) {
  @media (min-width: 1400px) {
    @content();
  }
}

.media-xs-down(@content) {
  @media (max-width: 575.98px) {
    @content();
  }
}
.media-sm-down(@content) {
  @media (max-width: 767.98px) {
    @content();
  }
}
.media-md-down(@content) {
  @media (max-width: 991.98px) {
    @content();
  }
}
.media-lg-down(@content) {
  @media (max-width: 1199.98px) {
    @content();
  }
}
.media-xl-down(@content) {
  @media (max-width: 1399.98px) {
    @content();
  }
}

.media-xs(@content) {
  .media-xs-down(@content);
}
.media-sm(@content) {
  @media (min-width: 576px) and (max-width: 767.98px) {
    @content();
  }
}
.media-md(@content) {
  @media (min-width: 768px) and (max-width: 991.98px) {
    @content();
  }
}
.media-lg(@content) {
  @media (min-width: 992px) and (max-width: 1199.98px) {
    @content();
  }
}
.media-xl(@content) {
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    @content();
  }
}
.media-xxl(@content) {
  .media-xxl-up(@content);
}