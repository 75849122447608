.loading {
  text-align: center;
  &:not(.loading-vertical) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div {
    padding: 2px;
  }
}