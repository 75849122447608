#notifications {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  overflow: auto;
  z-index: 999999;
  pointer-events: none;

  .toast {
    min-width: 140px;
    max-width: 250px;
    opacity: 1;
    display: block;
    text-align: left;
    margin: 7px 0 7px auto;
    pointer-events: all;
  }
}